import {onInit} from '../app';
import './validator/initialisation';

export function getRecaptcha()
{
    let url = new URL(document.querySelector('script[src^="https://www.google.com/recaptcha/api.js"]').src);
    let params = new URLSearchParams(url.search);
    return grecaptcha.execute(params.get('render'));
}

$.fn.ajaxInvalidFeedback = function (options) {
    return this.each(function () {
        let object = $(this).data('ajaxInvalidFeedback');
        if (typeof options == 'object') {
            $(this).addClass('has-invalid-feedback-ajax');
            if ($(this).attr('id') && $(this).closest('form').find('.invalid-feedback.' + $(this).attr('id')).length > 0) {
                let customErrorFieldResult = $(this).closest('form').find('.invalid-feedback.' + $(this).attr('id'));
                customErrorFieldResult.siblings('.invalid-feedback-ajax').remove();
                customErrorFieldResult.after(`<div class='invalid-feedback invalid-feedback-ajax d-block custom-error-feedback'> ${options.error} </div>`);
            } else {
                $(this).after(`<div class='invalid-feedback invalid-feedback-ajax d-block'> ${options.error} </div>`);
            }

            let destroyHandler = () => $(this).ajaxInvalidFeedback('destroy');
            $(this).on('change input', destroyHandler);
            $(this.form).on('submit', destroyHandler);
            $(this).data('ajaxInvalidFeedback', {destroyHandler});
        } else if (options === 'destroy') {
            if ($(this).attr('id') && $(this).closest('form').find('.invalid-feedback.' + $(this).attr('id')).length > 0) {
                $('.invalid-feedback.' + $(this).attr('id')).siblings('.invalid-feedback-ajax').remove();
            }
            if (object !== null) {
                $(this).off('change input', object.destroyHandler).removeClass('has-invalid-feedback-ajax').siblings('.invalid-feedback-ajax').remove();
                $(this.form).off('submit', object.destroyHandler);
            }
            $(this).data('ajaxInvalidFeedback', null);
        }
    });
};

$.fn.toggleValid = function (valid) {
    this.each((i, element) => element.setCustomValidity(valid ? "" : 'invalid'));
};

export function processFormErrors(errors, form)
{
    for (let fieldName in errors) {
        if (fieldName == form.name) {
            for (let error of errors[fieldName]) {
                alert(error);
            }
        } else {
            let error = errors[fieldName].join('<br>');
            $(form.elements[fieldName]).ajaxInvalidFeedback({error: error});
        }
    }
}

export async function setRecaptchaResponse(form)
{
    if ($(form).data('recaptcha') && !$(form.elements).filter('.g-recaptcha-response').length) {
        let fieldName = typeof $(form).data('recaptcha') == 'string' ? $(form).data('recaptcha') : 'g-recaptcha-response';
        $(form).append(` <input type="hidden" name="${fieldName}" class = "g-recaptcha-response"> `);
    }
    if ($(form.elements).filter('.g-recaptcha-response').length) {
        $(form.elements).filter('.g-recaptcha-response').val(await getRecaptcha());
    }
}

// $('.ajax-form').on('submit', async function (event) {
onInit('.ajax-form', el => $(el).on('submit', async function (event) {
    event.preventDefault();
    if ($(this).data('temporary-disabled')) {
        console.warn('Form is already being submitted');
        return false;
    }
    $(this).data('temporary-disabled', true);
    $(this.elements).filter('[type=submit]').prop('disabled', true);
    await setRecaptchaResponse(this);

    try {
        var data = await $.ajax({
            type: 'post',
            url: this.action,
            data: new FormData(this),
            processData: false,
            contentType: false,
        });
    } catch (xhr) {
        let errorEvent = $.Event('submit-error');
        $(this).trigger(errorEvent, xhr);
        if (!errorEvent.isDefaultPrevented()) {
            if (xhr.responseJSON.registration && xhr.responseJSON.registration == 1) {
                if (xhr.responseJSON.registrationErrorGlobal) {
                    $('#registration-result').html(`<div class="alert alert-danger">${xhr.responseJSON.registrationErrorGlobal}</div>`);
                    if (xhr.responseJSON.redirectStep) {
                        activateStep(xhr.responseJSON.redirectStep);
                    }
                }
                if (xhr.responseJSON.errors) {
                    processFormErrors(xhr.responseJSON.errors, this);
                }
            } else if (xhr.responseJSON.showAsGlobalError) {
                if (xhr.responseJSON.globalError) {
                    $('#global-error-result').html(`<div class="alert alert-danger">${xhr.responseJSON.globalError}</div>`);
                }
                if (xhr.responseJSON.errors) {
                    processFormErrors(xhr.responseJSON.errors, this);
                }
            } else if (xhr.responseJSON && xhr.responseJSON.errors) {
                processFormErrors(xhr.responseJSON.errors, this);
            } else {
                alert("Wystąpił nieznany błąd, spróbuj ponownie później.");
            }
        }
        $('html, body').animate({
            scrollTop: $(this).offset().top - 96
        }, 0);
        return;
    } finally {
        $(this).data('temporary-disabled', false);
        $(this.elements).filter('[type=submit]').prop('disabled', false);
    }
    let successEvent = $.Event('submit-success');
    $(this).trigger(successEvent, data);
    if (!successEvent.isDefaultPrevented()) {
        this.reset();
        $(this).removeClass('was-validated');
        $(this).find('.has-invalid-feedback-ajax').ajaxInvalidFeedback('destroy');
        $(this).find('.is-valid').removeClass('is-valid');
        $(this).find('.is-invalid').removeClass('is-invalid');
        $(this).find('.form-file .btn-remove').hide();
        $(this).find('img[data-src]').each(function () {
            $(this).attr('src', $(this).data('src'));
        });

        if (data.message) {
            alert(data.message, data.messageType || 'success');
        }
        if (data.redirect) {
            location.href = data.redirect;
        }
        if (data.reload) {
            location.reload();
        }
        if (data.needsCustomModal === 1) {
            showCustomModal(data.type, data.heading, data.text);
        }
    }
}));

let $ajaxTimeAlert = $('.ajax-time-alert');
if ($ajaxTimeAlert.length) {
    setTimeout(function () {
        $('.ajax-time-alert').remove();
    }, 3000);
}

// form select

onInit('.form-select select', function () {

    var $this = $(this),
        numberOfOptions = $(this).children('option').length,
        selected = $this.children('[selected]'),
        selectedOption = selected[0],
        selectedOptionIndex = selected.index();

    $this.addClass('form-select__hidden');
    $this.after('<div class="form-select__selected"></div>');

    var $styledSelect = $this.next('.form-select__selected');
    $styledSelect.text($this.children('option').eq(0).text());

    var $list = $('<ul />', {
        'class': 'form-select__options'
    }).insertAfter($styledSelect);

    for (var i = 0; i < numberOfOptions; i++) {
        $('<li />', {
            html: $this.children('option').eq(i).text(),
            rel: $this.children('option').eq(i).val()
        }).appendTo($list);
    }

    var $listItems = $list.children('li');

    if (selectedOption) {
        var selectedOptionValue = $(selectedOption).text();
        $this.parent().addClass('form-select__select--filled');
        $styledSelect.text(selectedOptionValue);
        $listItems.eq(selectedOptionIndex).addClass('active');
    }

    $styledSelect.click(function (e) {
        e.stopPropagation();

        if ($(this).parent().hasClass('form-select__select--active')) {
            $(this).parent().removeClass('form-select__select--active');
            $list.slideUp(200);
            return false;
        } else {
            $('.form-select').removeClass('form-select__select--active');
            $('.form-select__options').slideUp(200);
            $(this).parent().addClass('form-select__select--active');
            $list.slideDown(200);
        }

    });

    $listItems.click(function (e) {
        e.stopPropagation();
        let rel = $(this).attr('rel');
        if (rel) {
            $this.parent().addClass('form-select__select--filled');
            $this.parent().removeClass('form-select__select--error');
        } else {
            $this.parent().removeClass('form-select__select--filled');
        }
        $styledSelect.text($(this).text());
        $this.val($(this).attr('rel'));
        $(this).addClass('active').siblings().removeClass('active');
        $list.parent().removeClass('form-select__select--active');
        $list.slideUp(200);
        $this.trigger('change');
    });

    $list.click(function (e) {
        e.stopPropagation();
    });

    $(document).click(function () {
        $list.parent().removeClass('form-select__select--active');
        $list.slideUp(200);
    });

});

// form check

$('.form-check--boolean input').on('change', function () {

    let value = $(this).prop('checked'),
        name = $(this).attr('name'),
        $field = $('[data-field-name="' + name + '"]');

    if (value && (!$field.data('fieldValue') || $field.data('fieldValue') == $(this).val())) {
        $field.css('display', 'flex');
    } else {
        $field.css('display', 'none');
    }

});

$('.form-check input').each(function () {

    let value = $(this).prop('checked'),
        name = $(this).attr('name'),
        $field = $('[data-field-name="' + name + '"]');

    if (value && (!$field.data('fieldValue') || $field.data('fieldValue') == $(this).val())) {
        $field.css('display', 'flex');
    } else {
        $field.css('display', 'none');
    }

});
