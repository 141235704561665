import { onInit } from './app';
import 'bootstrap';
import 'bootstrap-autocomplete';

onInit('.should-buy-more-select', el => $(el).on('change', function() {
    $.get($('.should-buy-more-box').data('url').replace('month', $(this).val()), function(data) {
        $('.should-buy-more-box').replaceWith(data);
        $('.should-buy-more-box').initJs();
    });
}));

onInit('.rabc-targets-select', el => $(el).on('change', function() {
    $.get($('.rabc-targets-box').data('url').replace('year/quarter', $(this).val()), function(data) {
        $('.rabc-targets-box').replaceWith(data);
        $('.rabc-targets-box').initJs();
    });
}));

$('.search-company-input').autoComplete();
$('.search-company-input').on('autocomplete.select', function(e, item) {
    location.href = `/company/${item.value}`;
});

$('.search-company-fst-input').autoComplete();
$('.search-company-fst-input').on('autocomplete.select', function(e, item) {
    location.href = `/company-fst/${item.value}`;
});

$(function() {
    $('.generate-code').on('click', function (e) {
        e.preventDefault();

        const url = '/generate-verification-code';

        $.ajax({
            url: url,
            type: 'GET',
            complete: function (response) {
                const code = response.responseJSON;
                $('#verification-code').text(code.code);
                $(".button-code").attr("data-url", code.code);
            }
        });
    });
});