import $ from 'jquery';
// import { onInit } from './app';

let articles = document.querySelectorAll('.news-expand-ajax');
if (articles) {
    articles.forEach(function (article) {
        article.addEventListener('click', function () {
            let id = article.getAttribute('data-id');
            let path = article.getAttribute('data-path');

            $.ajax({
                url: path,
                type: 'POST',
                data: {id: id},
                success: function (response) {
                    $(article).parent().removeClass('has-notification');
                    let hideNotification = response.hideNotification;
                    if (hideNotification === 1) {
                        $('.articles-notification-alert').removeClass('has-notification');
                    }
                },
            });
        });
    });
}

$('.agreement-checkbox-ajax').on('click', function (e) {
    let id = $(this).data('id');
    let url = $(this).data('path');
    let urlPrepare = $(this).data('path-prepare');
    let isChecked = $(this).prop('checked');
    if (isChecked === false) {
        e.preventDefault();
        $.ajax({
            url: urlPrepare,
            type: 'POST',
            data: {},
            cache: false,
            contentType: false,
            processData: false,
            success: function (response) {
                showCustomModal(
                    'confirmationAjax',
                    response.heading,
                    response.text,
                    url,
                    response.confirmBtnText,
                    response.cancelBtnText,
                    response.icon,
                    id,
                    'confirmUncheckAgreement'
                );
            },
            error: function (response) {
                closeCustomModal();
            }
        });
    }
});

window.confirmUncheckAgreement = function (id, url) {
    $('#agree_' + id).prop('checked', false);
    agreementChangeFunction(id, url);
}

window.agreementChangeFunction = function (id, url, isChecked = false) {

    let formData = new FormData();
    formData.append("id", id);
    formData.append("isChecked", isChecked);

    let agreementResult = $('#agreement-result-' + id);
    agreementResult.html('');
    agreementResult.removeClass('b-tab__header-check-text--green');
    agreementResult.removeClass('b-tab__header-check-text--red');

    if (isChecked === true) {
        agreementResult.addClass('b-tab__header-check-text--green');
    } else {
        agreementResult.addClass('b-tab__header-check-text--red');
    }

    $.ajax({
        url: url,
        type: 'POST',
        data: formData,
        cache: false,
        contentType: false,
        processData: false,
        success: function (response) {
            $('#agreement-result-' + id).html(response.message)
        },
        error: function (response) {
            $('#agreement-result-' + id).html(response.message)
        }
    });
}

$('.agreement-checkbox-ajax').on('change', function () {
    let id = $(this).data('id');
    let url = $(this).data('path');
    let isChecked = $(this).prop('checked');
    agreementChangeFunction(id,url,isChecked);
});

$('.manage-workers-btn').on('click', function () {
    $(this).css('display', 'none');
    $('.manage-workers-cta').addClass('is-active');
});

window.globalModalConfirmAjax = function () {
    $('.global-modal-confirm').on('click', function () {
        let id = $(this).data('id');
        let url = $(this).data('path');
        let customFunction = $(this).data('custom');
        $.ajax({
            url: url,
            type: 'POST',
            data: '',
            cache: false,
            contentType: false,
            processData: false,
            success: function (response) {
                if (response.reload === 1) {
                    location.reload();
                }
                if (customFunction === 'confirmUncheckAgreement') {
                    window[customFunction](id,url);
                }
                closeCustomModal()
            },
            error: function (response) {
                closeCustomModal()
            }
        });
    });
}

$('.delete-workers-btn').on('click', function () {
    let url = $(this).data('path');
    $.ajax({
        url: url,
        type: 'POST',
        data: '',
        cache: false,
        contentType: false,
        processData: false,
        success: function (response) {
            showCustomModal(
                'confirmationAjax',
                response.message,
                '',
                response.confirmPath,
                response.confirmBtnText,
                response.cancelBtnText,
                response.icon,
                response.id
            );
        },
        error: function (response) {
            closeCustomModal()
        }
    });
});

$('.merchandising-btn-submit').on('click', function () {
    let url = $(this).data('path');
    $.ajax({
        url: url,
        type: 'POST',
        data: '',
        cache: false,
        contentType: false,
        processData: false,
        success: function (response) {
            showCustomModal(
                'confirmationAjax',
                response.message,
                response.description,
                response.confirmPath,
                response.confirmBtnText,
                response.cancelBtnText,
                response.icon,
                response.id
            );
        },
        error: function (response) {
            closeCustomModal()
        }
    });
});

$('.promo-sign-btn').on('click', function () {
    let url = $(this).data('path');
    $.ajax({
        url: url,
        type: 'POST',
        data: '',
        cache: false,
        contentType: false,
        processData: false,
        success: function (response) {
            showCustomModal(
                'customHtml',
                '',
                response
            );
        },
        error: function (response) {
            closeCustomModal()
        }
    });
});

$('.modal-close-btn, .global-modal-cancel').on('click', function () {
    closeCustomModal()
});

window.showCustomModal = function (
    type,
    heading,
    text,
    confirmPath,
    confirmBtnText,
    cancelBtnText,
    icon,
    dataId,
    customFunction = ''
) {
    let modalDescription = $('#customModal .modal__description');
    $('#customModal').addClass('is-active');
    modalDescription.html('');

    let iconDiv = '<div class="global-modal-icon"></div>';
    let headingDiv = '<div class="global-modal-heading"></div>';
    let additionalDiv = '<div class="global-modal-desc"></div>';
    let actionsDiv = '<div class="modal-action"><button class="button global-modal-confirm" type="button"></button><button class="global-modal-cancel button" type="button"></button></div>';

    if (icon && icon !== '') {
        modalDescription.append(iconDiv);
        $('#customModal .global-modal-icon').html('<img src="' + icon + '" alt="">');
    }
    if (heading && heading !== '') {
        modalDescription.append(headingDiv);
        $('#customModal .global-modal-heading').html(heading);
    }
    if (text && text !== '') {
        modalDescription.append(additionalDiv);
        $('#customModal .global-modal-desc').html(text);
    }

    if (type && type === 'confirmationAjax') {
        modalDescription.append(actionsDiv);
        if (confirmBtnText && confirmBtnText !== '') {
            $('#customModal .global-modal-confirm').html(confirmBtnText).attr('data-path', confirmPath).attr('data-id', dataId).attr('data-custom', customFunction);
        }
        if (cancelBtnText && cancelBtnText !== '') {
            $('#customModal .global-modal-cancel').html(cancelBtnText);
        }
        $('#customModal .modal-close-btn, #customModal .global-modal-cancel').on('click', function () {
            closeCustomModal()
        });
        globalModalConfirmAjax();
    }

    if (type && type === 'redirectAction') {
        let singleActionDiv = '<div class="modal-action"><a href="" class="button global-modal-confirm"></a></div>';
        modalDescription.append(singleActionDiv);
        $('#customModal .global-modal-confirm').html(confirmBtnText).attr('href', confirmPath);
    }

    if (type && type === 'customHtml') {
        modalDescription.html(text);
    }

    $('html').addClass('is-modal');
}

// function closeCustomModal()
window.closeCustomModal = function () {
    $('#customModal').removeClass('is-active');
    $('html').removeClass('is-modal');
    $('#customModal .global-modal-heading').html('');
    $('#customModal .global-modal-confirm').html('').attr('data-path', '');
    $('#customModal .global-modal-cancel').html('');
}

if ($('#regulationsModal').length) {
    $('#regulationsModal').addClass('is-active');
    $('html').addClass('is-modal');
}
$('#regulationsCheckbox').on('change', function () {
    $('#btnConfirmRegulations').prop('disabled', !$(this).is(':checked'));
});

$('.upload-input-ajax').on('change', function () {
    uploadFile($(this));
});

window.uploadFile = function (element) {
    let url = $(element).data('path');
    let file = $(element).prop('files')[0];
    let resultId = $(element).data('result');

    let formData = new FormData();
    formData.append("file", file);
    formData.append("resultId", resultId);

    $.ajax({
        url: url,
        type: 'POST',
        data: formData,
        cache: false,
        contentType: false,
        processData: false,
        success: function (response) {
            if (response.status === 'successregistration') {
                // $('#' + resultId).html('<div class="alert alert-success ajax-time-alert">' + response.message + '</div>');
                $('#avatar-registration').removeClass('b-box-primary__account-avatar-image--empty');
                $('#avatar-registration img').attr('src','/upload/tmp/' + response.filename);
                $('#registration_workshop_avatar_filename').val(response.filename);
                // setTimeout(function () {
                //     $('.ajax-time-alert').remove();
                //     $('#avatar-registration').removeClass('b-box-primary__account-avatar-image--empty');
                //     $('#avatar-registration img').attr('src','/upload/tmp/' + response.filename);
                //     $('#avatar-filename').val(response.filename);
                // }, 3000);
            }
            if (response.reload === 1) {
                location.reload();
            }
            if (response.status === 'success') {
                $('#' + resultId).html('<div class="alert alert-success ajax-time-alert">' + response.message + '</div>');
                setTimeout(function () {
                    location.reload()
                }, 2000);
            }
            if (response.status === 'failed') {
                $('#' + resultId).html('<div class="alert alert-danger ajax-time-alert">' + response.message + '</div>');
            }
        },
        error: function (response) {
            if (response.responseJSON.status === 'failedregistration') {
                $('#avatar_filename').val('');
                $('#avatar-registration').addClass('b-box-primary__account-avatar-image--empty');
                $('#avatar-registration img').attr('src','');
                $('#' + resultId).html('<div class="alert alert-danger ajax-time-alert">' + response.responseJSON.message + '</div>');
                setTimeout(function () {
                    $('.ajax-time-alert').remove();
                }, 3000);
            } else {
                $('#' + resultId).html('<div class="alert alert-danger ajax-time-alert">' + response.responseJSON.message + '</div>');
            }
        }
    });

    $(element).val('');
}

document.addEventListener('DOMContentLoaded', function () {
    let urlParams = new URLSearchParams(window.location.search);
    let scrollValue = urlParams.get('scroll');
    if (scrollValue) {
        window.scrollTo(0, document.getElementById(scrollValue).offsetTop - 100);
    }
});

window.showIntroPopup = function (type, heading, text, confirmPath, confirmBtnText, icon) {
    showCustomModal(type, heading, text, confirmPath, confirmBtnText, '',icon);
    $('.global-modal-confirm').html(confirmBtnText).addClass('into-action');
    $('.modal-close-btn').addClass('intro-close');
    $('.into-action, .intro-close').on('click', function () {
        let expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 365);
        document.cookie = 'new-page-info=1; expires=' + expirationDate.toUTCString();
    });
}

$('.rabc-show-hide').on('click', function () {
    $('.sales-prev-edition').toggleClass('is-hidden');
    $(this).toggleClass('is-active');
});

$('.branch-edit-btn').on('click', function () {
    let id = $(this).data('id');
    let url = $(this).data('path');

    $.ajax({
        url: url,
        type: 'POST',
        data: {},
        cache: false,
        contentType: false,
        processData: false,
        success: function (response) {
            $('.branch-edit-form').html('').removeClass('is-active');
            $('#branch-edit-' + id).addClass('is-active').html(response.html).initJs();
        },
        error: function (response) {
            $('.branch-edit-form').html('').removeClass('is-active');
            $('#branch-edit-' + id).addClass('is-active').html(response.responseJSON.message);
        }
    });
});

window.loadRegistrationForm = function (url) {
    $.ajax({
        url: url,
        type: 'POST',
        data: '',
        cache: false,
        contentType: false,
        processData: false,
        success: function (response) {
            $('#registration-content').html(response).initJs();
        },
        error: function (response) {
            alert('Error');
        }
    });
}

window.checkGUSData = function (url) {
    $.ajax({
        url: url,
        type: 'GET',
        data: '',
        cache: false,
        contentType: false,
        processData: false,
        success: function (response) {
            if (response.status == 'success') {
                $('.gusDataCompanyName').val(response.gusData.companyName).removeClass('has-invalid-feedback-ajax').next('.invalid-feedback-ajax').remove();
                $('.gusDataStreet').val(response.gusData.street).removeClass('has-invalid-feedback-ajax').next('.invalid-feedback-ajax').remove();
                $('.gusDataStreetNr').val(response.gusData.streetNr).removeClass('has-invalid-feedback-ajax').next('.invalid-feedback-ajax').remove();
                $('.gusDataApartmentNr').val(response.gusData.apartmentNr).removeClass('has-invalid-feedback-ajax').next('.invalid-feedback-ajax').remove();
                $('.gusDataZipCode').val(response.gusData.zipCode).removeClass('has-invalid-feedback-ajax').next('.invalid-feedback-ajax').remove();
                $('.gusDataCity').val(response.gusData.city).removeClass('has-invalid-feedback-ajax').next('.invalid-feedback-ajax').remove();
            } else {
                alert('Error');
            }
        },
        error: function (response) {
            alert('Error');
        }
    });
}

$('.training-rules-btn').on('click', function () {
    let url = $(this).data('path');
    $.ajax({
        url: url,
        type: 'POST',
        data: '',
        cache: false,
        contentType: false,
        processData: false,
        success: function (response) {
            showCustomModal(
                'customHtml',
                '',
                response
            );
        },
        error: function (response) {
            closeCustomModal()
        }
    });
});

window.loadSummary = function (url) {
    let resultId = $('#summary-content');
    resultId.html('');
    let formData = new FormData();

    $.ajax({
        url: url,
        type: 'POST',
        data: formData,
        cache: false,
        contentType: false,
        processData: false,
        success: function (response) {
            if (response.status === 'success') {
                resultId.html(response.template).initJs();
            }
        },
        error: function (response) {
            resultId.html(response.responseJSON.message);
        }
    });
}

$('.load-summary-transfer').on('click', function () {
    let url = $(this).data('path');
    let amount = $('#amount').val();
    let resultId = $('#summary-content');
    resultId.html('');
    let formData = new FormData();
    formData.append("amount", amount);
    $.ajax({
        url: url,
        type: 'POST',
        data: formData,
        cache: false,
        contentType: false,
        processData: false,
        success: function (response) {
            if (response.status === 'success') {
                resultId.html(response.template).initJs();
            }
        },
        error: function (response) {
            resultId.html(response.responseJSON.message);
        }
    });
});

window.loadCart = function (url) {
    let formData = new FormData();
    $.ajax({
        url: url,
        type: 'POST',
        data: formData,
        cache: false,
        contentType: false,
        processData: false,
        success: function (response) {
            $('#cart-content').html(response.template)
            document.dispatchEvent(cartLoadedEvent);
        },
        error: function (response) {
            console.log(response.message)
        }
    });
}

window.loadProducts = function (url, data) {
    $.ajax({
        url: url,
        type: 'POST',
        data: data,
        cache: false,
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        processData: true,
        success: function (response) {
            $('#products-list').html(response.template)
            document.dispatchEvent(productsLoadedEvent);
        },
        error: function (response) {
            console.log(response.message)
        }
    });
}

window.addToCart = function (url, data, cartUrl) {
    $.ajax({
        url: url,
        type: 'POST',
        data: data,
        cache: false,
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        processData: true,
        success: function (response) {
            $('#karta-produktu').find('.btn-close').click();
            loadCart(cartUrl);
            $('html, body').animate({
                scrollTop: $('#cart-content').offset().top - 50
            }, 1000);
        },
        error: function (response) {
            console.log(response.message)
        }
    });
}

window.cartAction = function (url, id, action, cartUrl, extra = {}) {
    $.ajax({
        url: url,
        type: 'POST',
        data: {...extra, id, action},
        cache: false,
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        processData: true,
        success: function (response) {
            loadCart(cartUrl);
        },
        error: function (response) {
            console.log(response.message)
        }
    });
}

/** Cart events **/
const productsLoadedEventName = 'productsLoaded';
const productsLoadedEvent = new Event(productsLoadedEventName);
const cartLoadedEventName = 'cartLoaded';
const cartLoadedEvent = new Event(cartLoadedEventName);
const productItemLoadedEventName = 'productItemLoaded';
const getUserPoints = () => {
    const item = document.querySelector('[data-user-points]');
    let availablePoints = item ? parseInt(item.dataset.userPoints) : 0;
    document.querySelectorAll('[data-cart-item-total-price]').forEach(item => {
        availablePoints -= parseInt(item.dataset.cartItemTotalPrice);
    });
    return availablePoints;
}
const disableTooExpensiveProducts = () => {
    const userPoints = getUserPoints();
    document.getElementById('products-list')
        .querySelectorAll('[data-product-price]')
        .forEach(item => {
            if (parseInt(item.dataset.productPrice) <= userPoints) {
                item.classList.remove('disabled');
            } else {
                item.classList.add('disabled');
            }
        });
}
const disableTooExpensiveProductDetailForm = () => {
    const userPoints = getUserPoints();
    const modal = document.getElementById('karta-produktu');
    const item = modal.querySelector('[data-product-price]');
    if (parseInt(item.dataset.productPrice) <= userPoints) {
        return;
    }
    modal.querySelector('form')?.classList.add('d-none');
    modal.querySelector('#add-to-cart')?.classList.add('d-none');
}
const setMaxProductsInProductDetailForm = () => {
    const userPoints = getUserPoints();
    const modal = document.getElementById('karta-produktu');
    const item = modal.querySelector('[data-product-price]');
    const max = Math.floor(userPoints / parseInt(item.dataset.productPrice));
    modal.querySelector('[name="quantity"]').setAttribute('max', max);
}
const setMaxProductsInCartForm = () => {
    const userPoints = getUserPoints();
    document.querySelectorAll('[data-cart-item-price]').forEach(item => {
        const input = item.querySelector('input');
        const max = parseInt(input.value) + Math.floor(userPoints / parseInt(item.dataset.cartItemPrice));
        input.setAttribute('max', max);
    });
}
document.addEventListener(productsLoadedEventName, disableTooExpensiveProducts);
document.addEventListener(cartLoadedEventName, disableTooExpensiveProducts);
document.addEventListener(cartLoadedEventName, setMaxProductsInCartForm);
document.addEventListener(productItemLoadedEventName, disableTooExpensiveProductDetailForm);
document.addEventListener(productItemLoadedEventName, setMaxProductsInProductDetailForm);

$('.tooltip-points-btn').on('click', function (e) {
    e.preventDefault();
    $('.tooltip-info').toggleClass('is-active');
});

$(document).on('click', function (e) {
    if (!$(e.target).closest('.tooltip-info').length && !$(e.target).closest('.tooltip-points-btn').length) {
        $('.tooltip-info').removeClass('is-active');
    }
});
